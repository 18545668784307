import React from "react";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Shopify Development Company`,
    content:
      "Partner with a top-tier Shopify development company to revitalize your store globally on the most widely used eCommerce platform.",
  };

  const commonContent6 = {
    title: "Shopify Development Services",
    para: "Do you want to utilize this powerful platform fully? Invest in our expert Shopify development services to set your store apart from the competition.",
    img: ["what-service-image-reactjs.webp"],
    imageTitle: 'Shopify Development',
    imageAlt: 'We are a leading Shopify development company',
    content: [
      {
        title: "Shopify Custom Development",
        text: "Our Shopify developer creates unique, tailored themes from scratch to match the brand's identity and user experience requirements.",
      },
      {
        title: "Shopify Web Development",
        text: "Our Shopify web developer develops custom Shopify websites and apps to add specific functionalities and features unavailable in the App Store.",
      },
      {
        title: "Store Ecommerce Development",
        text: "Our Shopify development services allow you to set up a new Shopify store, configure payment gateways and shipping methods, and install necessary apps and plugins.",
      },
      {
        title: "Migration to Shopify",
        text: "Our Shopify developers migrate an existing eCommerce store from another platform (e.g., Magento, WooCommerce) to Shopify, ensuring data integrity and seamless transition.",
      },
      {
        title: "Custom Integrations",
        text: "Our Shopify app development services integrate third-party services and APIs (like CRM, ERP, and payment gateways) to streamline business processes and enhance store functionality.",
      },
      {
        title: "SEO Implimentation",
        text: "Our Shopify ecommerce development company implements SEO best practices within the Shopify store to improve search engine rankings and drive organic traffic.",
      },
      {
        title: "Maintenance and Support",
        text: "Our web development company provides ongoing support and maintenance services to ensure the Shopify store remains updated, secure, and running smoothly.",
      },
    ],
  };

  const commonContent = {
    title: "Our Industry’s Approach as Shopify Web Development Company",
    para: "Our Shopify web development company works with startups and major corporations in various industries to build dynamic Shopify websites and redesign existing ones.",
    img: ["who-needs-reactjs.webp"],
    imageTitle: 'Shopify Development',
    imageAlt: 'Driving industry growth with our Shopify web Development',
    content: [
      {
        title: "E-commerce",
        text: "Companies seek to create dynamic and highly functional websites to give seamless website experiences to their customers.",
      },
      {
        title: "SMBs",
        text: "Companies are looking to boost their online presence using low-cost alternatives for designing websites based on consumer requirements.",
      },
      {
        title: "Startups",
        text: "Companies looking to grow their business by embracing Shopify’s cutting-edge tools and technology.",
      },
      {
        title: "Enterprises",
        text: "Companies that wish to simplify their complex platform while providing their clients with a high-performing, reliable platform for a better experience.",
      },
    ],
  };

  const commonContent2 = {
    title: "Build Better with Shopify App Development Services",
    para: "Shopify App Development Services allows businesses to design robust interfaces with extensive tools, resulting in highly functional, responsive, and intuitive Shopify applications.",
    img: ["what-are-benefits-reactjs-services.webp"],
    imageTitle: 'Shopify web development',
    imageAlt: 'choose better by choosing Shopify developer',
    content: [
      {
        title: "Increased Efficiency",
        text: "Shopify development provides reusable components and streamlines workflows, reducing development time and increasing productivity.",
      },
      {
        title: "Scalability",
        text: "Shopify web development services provide scalable solutions for your business, keeping your website updated with the latest trends while ensuring performance.",
      },
      {
        title: "Enhanced User Experience",
        text: "Shopify app development services help create visually interactive, functional, responsive websites that improve user experience.",
      },
      {
        title: "Extensive Ecosystem",
        text: "Shopify web development company offers an innovative solution for your business through its extensive plugins, community-driven tools, and libraries.",
      },
    ],
  };
  const commonContent3 = {
    title: "Work with the Top Shopify Ecommerce Development Company",
    linkText: ``,
    para: `Octet Design Studio is a leading Shopify ecommerce development company that creates innovative interfaces using our significant industry knowledge. We use our expertise in Shopify development servies to develop customized and robust online solutions for your company.`,
    img: ["why-opt-for-reactjs.webp"],
    imageTitle: 'Shopify Development Company',
    imageAlt: 'Work with the best Shopify web development company',
    content: [
      {
        title: "Expertise and Experience",
        text: "We have appropriate expertise and experience in providing Shopify Development and better-than-expected results.",
      },
      {
          title: "Timely Delivery",
          text: "We streamline the development process to ensure your projects run smoothly and on time.",
        },
        {
          title: "Customized Solutions",
          text: "We take a personalized approach to each project and provide tailored Shopify Custom Development solutions depending on your business objectives.",
        },
      {
        title: "Dedicated Team",
        text: "We provide a specialized team for your Shopify web development, who will turn your ideas into reality and ensure timely delivery.",
      },
    ],
  };
  const commonContent4 = {
    title: "Octet’s Shopify Development Process",
    para: "We provide complete and personalized Shopify development services based on your project requirements, ensuring seamless integration and business success.",
    img: ["how-we-conduct-react-js-services.webp"],
    imageTitle: 'Shopify Process',
    imageAlt: 'Octet’s Shopify web development process',
    content: [
      {
        title: "1. Define UX & Flows",
        text: "We initially identify the UX characteristics and user processes to determine the design structure.",
      },
      {
        title: "3. Create HTML Templates",
        text: "We transform the UI design into HTML templates, which are then translated into Shopify pages.",
      },
      {
        title: "2. Create UI Designs",
        text: "We create designs while considering brand requirements, typographic design principles, color theory, and UX rules.",
      },
      {
        title: "4. Convert to VueJS Pages",
        text: "Finally, we transfer the HTML code to the Shopify framework for easy comprehension and faster deployment.",
      },
    ],
  };
  const commonContent5 = {
    title:
      "Why Invest in the Shopify Development Company?",
    para: "Shopify is a progressive framework for developing dynamic and interactive interfaces. Investing in our Shopify Web Development Company enables your company to create cost-effective, high-quality websites.",
    content: [
      {
        title: "30% Less Development Time",
        text: "Shopify Development’s simplicity and reactivity can help accelerate development cycles, lowering development time and other resource expenditures.",
      },
      {
        title: "20% Code Reusability",
        text: "The Shopify web development framework's component-based architecture promotes code reuse and maintainability, potentially increasing returns.",
      },
      {
        title: "10% Increased Productivity",
        text: "Shopify development’s excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      }, 
      {
        title: "10% Increased Productivity",
        text: "Shopify’s excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them*.",
  };
  const cta = {
    title: "Expand your Horizons with our Shopify Development",
  };
  const cta2 = {
    title: "Ready To Build your Next Online Store?",
  };
  const cta3 = {
    title: "Turn Great Ideas into Exceptional Reality",
  };
  const cta4 = {
    title: "Get Expertise from our Shopify Developer",
  };
  const cta5 = {
    title: "Let’s Develop your Next Digital Store",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
        title: "S2C Help Manual",
        desc: "A Research Tech Platform",
        Industry: "Enterprise",
        firstAlt: "UI UX Screen To Create Survey",
        firstTitle: "Create Survey",
        secondAlt:
          "UI Screen To Create Personalized Survey Using Specified Categories",
        secondTitle: "Select Category",
        WhatWeDid: [
          {
            title: "UX Consultation",
            link: "/design-audit/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "Wordpress Development",
            link: "/wordpress-development/",
          },
        ],
        link: "/project/s2c-help-manual/",
        images: ["help-manual.webp", "help-manual-2.webp"],
    },
    {
        title: "Developly",
        desc: "A Code E-Learning Platform",
        Industry: "Enterprise",
        firstAlt: "User Dashboard To Chek Their Code E-learning Status",
        firstTitle: "User Dashboard",
        secondAlt:
          "UI Screen To Check The Progress Of The User For A Particular Course",
        secondTitle: "Progress Screen",
        WhatWeDid: [
          {
            title: "Branding",
            link: "/branding/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "UI Development",
            link: "/ui-development/",
          },
        ],
        link: "/project/ui-ux-design-developly/",
        images: ["developly-web.webp", "developly-2.webp"],
    },
  ];

  const Questions = [
    "Can you provide Shopify Custom Development?",
    "Can you integrate Shopify into existing web applications?",
    "How does your Shopify Web Development improve user experience?",
    "What is the duration and cost of your Shopify Development Services?",
  ];
  const clientsTitle = "Trusted By Clients Worldwide";
  const faqDes =
    `We understand the questions you may have concerning our Shopify Development Services. In this area, we share additional information on our Shopify Development Company. However, if you still have any questions, please <a title='contact us' target="_blank" rel="noreferrer" href="/contact-us/">contact us</a>.`;
  
    const faqData = [
      {
        para: [
          `Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.`,
          `Shopify development is a flexible Framework that we use for customization and offers robust web and app solutions.`,
          `To offer a tailor-made Shopify development service and enhance your user experience, we understand your business objectives, target audience, and specific project requirements.`
        ],
      },
      {
        para: [
          `Yes, we can seamlessly integrate our Shopify Development Services into existing applications because of the platform's versatility and flexibility.`,
          `We consider Shopify to be the best option for enhancing the functionality of your application.`,
          `It assists in improving your business's performance, enhances user experience, and offers easily scalable options.`
        ],
      },
      {
        para: [
          `Our Shopify web development enables us to provide highly creative and responsive user interfaces based on your business requirements.`,
          `We develop dynamic websites through Shopify and update them using the latest technologies to create an engaging interface.`,
          `With our flexible approach and library, we offer optimized websites, improved usability, and an intuitive user experience.`
        ],
      },
      {
        para: [
          `The duration and cost of our Shopify Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you <a title='contact us' target='_blank' href='/contact-us/'>contact us</a> for an accurate timeline and detailed quotation. `
        ],
      }
    ];
  
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Can you provide Shopify Custom Development?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.
              Shopify development is a flexible Framework that we use for customization and offers robust web and app solutions.
              To offer a tailor-made Shopify development service and enhance your user experience, we understand your business objectives, target audience, and specific project requirements."
                  }
                },{
                  "@type": "Question",
                  "name": "Can you integrate Shopify into existing web applications?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we can seamlessly integrate our Shopify Development Services into existing applications because of the platform's versatility and flexibility.
              We consider Shopify to be the best option for enhancing the functionality of your application.
              It assists in improving your business's performance, enhances user experience, and offers easily scalable options."
                  }
                },{
                  "@type": "Question",
                  "name": "How does your Shopify Web Development improve user experience?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our Shopify web development enables us to provide highly creative and responsive user interfaces based on your business requirements.
              We develop dynamic websites through Shopify and update them using the latest technologies to create an engaging interface.
              With our flexible approach and library, we offer optimized websites, improved usability, and an intuitive user experience."
                  }
                },{
                  "@type": "Question",
                  "name": "What is the duration and cost of your Shopify Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The duration and cost of our Shopify Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you contact us for an accurate timeline and detailed quotation."
                  }
                }]
              }                                    
            `}
          </script>
        </Helmet>
        <Layout>
          <div className="service-detail-page">
            <Banner content={banner} page={"service"} />
            <CommonServiceComponent data={commonContent6} mode={"grey"} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent} mode={"light"} />
            <Cta mode="grey" data={cta} />
            <CommonServiceComponent data={commonContent2} mode={"dark"} />
            <CommonServiceComponent data={commonContent3} mode={"light"} />
            <Cta data={cta2} />
            <Clients title={clientsTitle} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent4} mode={"light"} />
            <Cta mode="grey" data={cta3} />
            <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
              <div className="container">
                <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                    Our Successful Projects by <span className="h1-span">Shopify Development</span>
                </h2>
              </div>
              {projectData.map((data, index) => (
                <div key={index}>
                  <ProjectSection num={index} lastLength={projectData} data={data} />
                </div>
              ))}
            </section>
            <Testimonials title={"Client’s Take On Us"} />
            <Cta data={cta4} />
            <CommonServiceComponent data={commonContent5} condition mode={"light"} />
            <ServiceTab tabs={tabs} mode={"split"} />
            <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
            <Cta mode="grey" data={cta5} />
            {/* <KnowMoreSection /> */}
          </div>
        </Layout>
      </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="Shopify Development Company | Shopify Development Services" description="We are a leading Shopify development company offering custom Shopify development services, apps, and custom e-commerce store solutions." />
)